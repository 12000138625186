<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'

  export default {
    name: 'CoreBusiness',

    metaInfo: {
      title: 'Core Business',
      titleTemplate: 'Howellcare Industries Sdn Bhd Core Business',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { vmid: 'keyword', name: 'keyword', content: 'NITRILE GLOVE MANUFACTURING, CORE COMPETENCIES, DESIGN, FABRICATION, INSTALLATION, COMMISSIONING, PRODUCTION, Glove Production Line, Heating System, Water Purification System, Wastewater Treatment System, Automation System, Tanks, Chlorinator, Heating System, Machine and Production Line Structure, Water Purification System, Production Line, Utilities, Line Startup, Debugging, Optimization, Quality Assurance, Process Technology, ASTM and EN Product compliance, Nitrile gloves, Natural rubber gloves, PERAK, USA, UK, KEDAH, ACQUISITION AND REFURBISHMENT OF NITRILE GLOVE PRODUCTION LINE MACHINES, DISTRIBUTE AND EXPORT OF EXAMINATION GLOVE, TURNKEY PARTNER FOR NITRILE EXAMINATION GLOVE MANUFACTURING PLANT, NITRILE BUTADIENE LATEX (NBL) AND NITRILE EXAMINATION GLOVE MANUFACTURING PLANT, nitrile, glove, manufacturing, products, international' },
        { vmid: 'description', name: 'description', content: 'Howellcare Industries Sdn Bhd is a one-stop service provider for nitrile glove manufacturing. Delivering excellence in manufacturing international standard nitrile gloves for personal barrier protection.' },
      ],
    },

    extends: View,

    mixins: [
      LoadSections([
        'portfolio-main',
        'core-competencies-new',
        'trading-gloves',
        'turnkey',
        'before-footer',
      ]),
    ],

    props: {
      id: {
        type: String,
        default: 'core',
      },
    },
  }
</script>
